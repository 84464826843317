import ChangeRequest, {
  ChangeRequestType,
} from "../../src/models/ChangeRequest";

import ApiResourceCollection from "../../src/models/ApiResourceCollection";
import ChangeRequestsApi from "../../src/api/ChangeRequestsApi";
import ClaimDetail from "../../src/models/ClaimDetail";
import { ErrorsLogic } from "./useErrorsLogic";
import { NullableQueryParams } from "../utils/routeWithParams";
import { PortalFlow } from "./usePortalFlow";
import User from "src/models/User";
import { ValidationError } from "../../src/errors";
import { WithBenefitYearsProps } from "src/hoc/withBenefitYears";
import getRelevantIssues from "../../src/utils/getRelevantIssues";
import useCollectionState from "./useCollectionState";
import { useState } from "react";

const useChangeRequestsLogic = ({
  errorsLogic,
  portalFlow,
}: {
  errorsLogic: ErrorsLogic;
  portalFlow: PortalFlow;
}) => {
  const {
    collection: changeRequests,
    addItems: addChangeRequests,
    removeItem: removeChangeRequest,
    updateItem: setChangeRequest,
  } = useCollectionState(
    new ApiResourceCollection<ChangeRequest>("change_request_id", [])
  );

  // this state is used to figure out whether or not to load the
  // change requests for a particular absenceId. See similar logic
  // in useDocumentsLogic.
  const [loadedChangeRequests, setLoadedChangeRequests] = useState<{
    [fineos_absence_id: string]: { isLoading: boolean | null };
  }>({});

  const isLoadingChangeRequests = (absenceId: string) =>
    absenceId in loadedChangeRequests &&
    loadedChangeRequests[absenceId].isLoading === true;

  const hasLoadedChangeRequests = (absenceId: string) =>
    absenceId in loadedChangeRequests &&
    loadedChangeRequests[absenceId].isLoading === false;

  const setLoadedChangeRequestsAbsenceId = (absenceId: string) => {
    setLoadedChangeRequests((loadingChangeRequests) => {
      const changeRequests = { ...loadingChangeRequests };
      changeRequests[absenceId] = {
        isLoading: null,
      };
      return changeRequests;
    });
  };

  const changeRequestsApi = new ChangeRequestsApi();

  const loadAll = async (absenceId: string) => {
    // if changeRequests already contains change requests for absenceId, don't load again
    // or if we started making a request to the API to load change requests, don't load again
    if (
      hasLoadedChangeRequests(absenceId) ||
      isLoadingChangeRequests(absenceId)
    )
      return;

    errorsLogic.clearErrors();

    setLoadedChangeRequests((loadingChangeRequests) => {
      const changeRequests = { ...loadingChangeRequests };
      changeRequests[absenceId] = {
        isLoading: true,
      };
      return changeRequests;
    });

    try {
      const { changeRequests } =
        await changeRequestsApi.getChangeRequests(absenceId);

      addChangeRequests(changeRequests.items);
      setLoadedChangeRequests((loadingChangeRequests) => {
        const changeRequests = { ...loadingChangeRequests };
        changeRequests[absenceId] = {
          isLoading: false,
        };
        return changeRequests;
      });
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  const create = async (
    absenceId: string,
    optionalData?: Partial<ChangeRequest>
  ) => {
    errorsLogic.clearErrors();

    try {
      const { changeRequest } = await changeRequestsApi.createChangeRequest(
        absenceId,
        optionalData
      );

      // force reload of change requests for this absenceId next time `loadAll` is called
      setLoadedChangeRequestsAbsenceId(absenceId);

      portalFlow.goToNextPage(
        { changeRequest },
        {
          absence_id: absenceId,
          change_request_id: changeRequest.change_request_id,
        }
      );
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  const destroy = async (changeRequestId: string, absenceId: string) => {
    errorsLogic.clearErrors();

    try {
      await changeRequestsApi.deleteChangeRequest(changeRequestId);

      removeChangeRequest(changeRequestId);

      portalFlow.goToNextPage(
        {},
        {
          absence_id: absenceId,
          changeRequestDeletedSuccessfully: "true",
        }
      );
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  const update = async (
    changeRequestId: string,
    patchData: Partial<ChangeRequest>,
    claimDetail?: ClaimDetail,
    benefitYearStates?: Partial<WithBenefitYearsProps>,
    user?: User
  ) => {
    errorsLogic.clearErrors();

    try {
      const { changeRequest, warnings } =
        await changeRequestsApi.updateChangeRequest(changeRequestId, patchData);

      const issues = getRelevantIssues(warnings, [portalFlow.page]);

      setChangeRequest(changeRequest);

      if (issues.length) throw new ValidationError(issues);

      const params: NullableQueryParams = {
        absence_id: changeRequest.fineos_absence_id,
        change_request_id: changeRequestId,
        claim_id: claimDetail?.application_id,
      };

      portalFlow.goToNextPage(
        {
          changeRequest,
          claimDetail,
          ...benefitYearStates,
          user,
        },
        params
      );
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  const submit = async (changeRequestId: string) => {
    errorsLogic.clearErrors();

    try {
      const { changeRequest } =
        await changeRequestsApi.submitChangeRequest(changeRequestId);

      setChangeRequest(changeRequest);

      const params: NullableQueryParams = {
        absence_id: changeRequest.fineos_absence_id,
        change_request_id: changeRequestId,
        changeRequestSubmittedSuccessfully: "true",
      };

      portalFlow.goToNextPage({ changeRequest }, params);
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  const createAndSubmitWithdrawal = async (
    absenceId: string,
    claimCompleted?: boolean
  ) => {
    errorsLogic.clearErrors();

    try {
      // search for unsubmitted change requests first, since we can only have one in progress
      let changeRequest;
      changeRequest = changeRequests.items.find(
        (req) => req.submitted_time === null
      );

      // if there is not one in progess, create a withdrawal
      if (!changeRequest) {
        ({ changeRequest } = await changeRequestsApi.createChangeRequest(
          absenceId,
          {
            change_request_type: ChangeRequestType.withdrawal,
          }
        ));
      }

      setChangeRequest(changeRequest);

      // submit either the in progress change request or the newly created one
      ({ changeRequest } = await changeRequestsApi.submitChangeRequest(
        changeRequest.change_request_id
      ));

      // force reload of change requests for this absenceId next time`loadAll` is called
      setLoadedChangeRequestsAbsenceId(absenceId);

      const params: NullableQueryParams = {
        absence_id: absenceId,
        change_request_id: changeRequest.change_request_id,
        changeRequestWithdrawnSuccessfully: "true",
      };

      // route to either status page or applications page, depending on whether the claim is complete
      if (claimCompleted) {
        portalFlow.goToPageFor("VIEW_STATUS", { changeRequest }, params);
      } else {
        portalFlow.goToPageFor("VIEW_APPLICATIONS", { changeRequest }, params);
      }
    } catch (error) {
      errorsLogic.catchError(error);
    }
  };

  return {
    changeRequests,
    createAndSubmitWithdrawal,
    hasLoadedChangeRequests,
    isLoadingChangeRequests,
    loadAll,
    create,
    destroy,
    update,
    submit,
  };
};

export default useChangeRequestsLogic;
